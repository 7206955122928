/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import { AnimatePresence } from "framer-motion";
import React from "react";
import "./src/styles/global.css";

export const wrapPageElement = ({ element }) => (
  <div className="overflow-hidden">
    <AnimatePresence mode="wait">{element}</AnimatePresence>
  </div>
);
